ul {
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

ul li {
  margin-bottom: 4px;
  line-height: 150%;
}
